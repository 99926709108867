<template>
  <div>
    <header-desktop class="d-none d-lg-block"></header-desktop>
    <div class="d-block d-lg-none">
      <div class="headerMobile ">
        <UIHeaderMobile :name="namePage" />
        <div class="ml-auto">
          <NavbarIconsRep />
        </div>
      </div>
    </div>
    <div class="layoutSubMain min-vh-100">
      <div class="position-relative d-none d-lg-block" v-if="!isOnlyMobile">
        <UIBanner
          :classLoading="'placeholders-banner-game'"
          :keyData="'w_article'"
          :isShowCaption="false"
          :classImage="'img-common'"
        />
        <div class="bannerText">
         Hướng dẫn
          <!-- <span class="d-block font-18 font-weight-normal">
            Quý khách có thể tìm được các thông tin cần hỗ trợ tại đây
          </span> -->
        </div>
      </div>
      <div class="container mb-lg-5 mb-0 pt-lg-0 pt-4">
        <div class="border-5" :class="{ 'px-3': $route.params.slug }">
          <div class="row">
            <b-card v-if="$route.params.slug" no-body class="mb-lg-1 mb-3 myToggle d-block d-lg-none w-100">
              <b-card-header header-tag="header" class="" role="tab">
                <b-button
                  block
                  :class="visible ? null : 'collapsed'"
                  :aria-expanded="visible ? 'true' : 'false'"
                  aria-controls="collapse-4"
                  @click="visible = !visible"
                  variant="white"
                  class="myToggleBtn"
                >
                  <p class="font-15">{{ title }}</p>
                  <p class="btnChooseMoney"></p>
                </b-button>
              </b-card-header>
              <b-collapse
                v-model="visible"
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
                class="myToggleContent"
              >
                <b-card-body>
                  <div class="px-2 py-2" :class="{ 'bg-white': $route.params.slug }" v-if="$route.params.slug">
                    <div class="tabLuatChoi mb-2" v-if="isLuatChoi">
                      <p class="font-20 font-17-992 color-grey mb-2">Luật và cách chơi</p>
                      <a
                        class="tabItemLuatChoi pointer"
                        :class="{ activedTab: handlerActiveTab(item.tag) }"
                        @click="handlerLink('/luat-choi/' + item.tag)"
                        v-for="(item, index) in categoryLuatChoi"
                        :key="index"
                      >
                        {{ item.title }}
                      </a>
                    </div>
                    <div class="tabLuatChoi  mb-2" v-if="isSupport">
                      <p class="font-20 color-grey mb-2 font-17-992">Hỗ trợ tài khoản</p>
                      <a
                        class="tabItemLuatChoi pointer"
                        :class="{ activedTab: handlerActiveTab(item.tag) }"
                        @click="handlerLink('/ho-tro/' + item.tag)"
                        v-for="(item, index) in categorySupport"
                        :key="index"
                      >
                        {{ item.title }}
                      </a>
                    </div>
                    <div class="tabLuatChoi" v-if="isWithdraw">
                      <p class="font-20 color-grey mb-2 font-17-992">Nạp rút tiền</p>
                      <a
                        class="tabItemLuatChoi pointer"
                        :class="{ activedTab: handlerActiveTab(item.tag) }"
                        @click="handlerLink('/nap-rut-tien/' + item.tag)"
                        v-for="(item, index) in categoryWithdraw"
                        :key="index"
                      >
                        {{ item.title }}
                      </a>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- <div
              class="col-lg-3 pr-lg-2 py-4 mb-lg-0 mb-2 d-none d-lg-block"
              :class="{ 'bg-white': $route.params.slug }"
              v-if="$route.params.slug"
            >
              <div class="tabLuatChoi mb-4" v-if="isLuatChoi">
                <p class="font-20 color-grey mb-2">Luật và cách chơi</p>
                <router-link
                  class="tabItemLuatChoi pointer"
                  :class="{ activedTab: handlerActiveTab(item.tag) }"
                  :to="'/luat-choi/' + item.tag"
                  v-for="(item, index) in categoryLuatChoi"
                  :key="index"
                >
                  {{ item.title }}
                </router-link>
              </div>
              <div class="tabLuatChoi mb-4" v-if="isSupport">
                <p class="font-20 color-grey mb-2">Hỗ trợ tài khoản</p>
                <router-link
                  class="tabItemLuatChoi pointer"
                  :class="{ activedTab: handlerActiveTab(item.tag) }"
                  :to="'/ho-tro/' + item.tag"
                  v-for="(item, index) in categorySupport"
                  :key="index"
                >
                  {{ item.title }}
                </router-link>
                <router-link
                  class="tabItemLuatChoi pointer"
                  :class="{ activedTab: $route.path === '/ho-tro/cau-hoi-thuong-gap' }"
                  :to="'/ho-tro/cau-hoi-thuong-gap'"
                >
                  Câu hỏi thường gặp
                </router-link>
              </div>
              <div class="tabLuatChoi mb-4" v-if="isWithdraw">
                <p class="font-20 color-grey mb-2">Nạp rút tiền</p>
                <router-link
                  class="tabItemLuatChoi pointer"
                  :class="{ activedTab: handlerActiveTab(item.tag) }"
                  :to="'/nap-rut-tien/' + item.tag"
                  v-for="(item, index) in categoryWithdraw"
                  :key="index"
                >
                  {{ item.title }}
                </router-link>
              </div>
            </div> -->
            <div
              class="col-lg-12 pl-lg-2"
              :class="{ 'col-lg-12 ': !$route.params.slug, 'bg-white py-4 mb-3 mb-lg-0': $route.params.slug }"
            >
              <transition name="fade" mode="out-in">
                <router-view
                  class="h-100"
                  @updateTitleMobileArticleList="updateTitleMobileArticleList"
                  :namePage="namePage"
                  :component="component"
                  :key="$route.path"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <section class="bannerContact" v-if="!$route.params.slug">
        <div class="imgBackground">
          <img src="@/assets/img/banner-support.jpg" />
        </div>
        <div class="contentBannerContact">
          <div class="container h-100">
            <div class="d-lg-flex align-items-center w-100 h-100">
              <div
                class="d-flex flex-xs-column align-items-lg-end align-items-center justify-content-lg-between justify-content-center w-100 h-xs-100"
              >
                <div class="text-lg-left text-center w-300-576">
                  <h4>Bạn có vấn đề chưa rõ?</h4>
                  <p class="font-18 colorContentBannerContact">Đừng ngần ngại, hãy liên hệ ngay với chúng tôi.</p>
                </div>
                <router-link to="/lien-he" class="btnConfirm btnContact text-uppercase">Liên hệ</router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer class="d-none d-lg-block"></Footer>
  </div>
</template>
<script>
import HeaderDesktop from '../components/HeaderDesktop';
import Footer from '../components/Footer';
import UIHeaderMobile from '@/components/UI/Header/UIHeaderMobile';
import UIBanner from '@/components/UI/Banner/UIBanner';
import NavbarIconsRep from '../components/HeaderDesktop/NavbarIconsRep';

export default {
  name: 'app',
  components: {
    HeaderDesktop,
    Footer,
    NavbarIconsRep,
    UIHeaderMobile,
    UIBanner
    // ModalRegister,
  },
  data() {
    return {
      gameId: undefined,
      isLoading: false,
      namePage: 'Hỗ trợ',
      title: 'Hỗ trợ',
      component: null,
      categorySupport: [],
      categoryWithdraw: [],
      visible: true
    };
  },
  watch: {
    $route(to, from) {
      if (to != from && !to.params.slug) {
        this.updateTitleMobileArticleList('Hỗ trợ');
      }
    }
  },
  computed: {
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    },
    getListGame() {
      return this.$store.getters.getterGameUrl;
    },
    isLuatChoi() {
      return this.categoryLuatChoi && this.categoryLuatChoi.length > 0;
    },
    isSupport() {
      return this.categorySupport && this.categorySupport.length > 0;
    },
    isWithdraw() {
      return this.categoryWithdraw && this.categoryWithdraw.length > 0;
    },
    categoryLuatChoi: {
      get() {
        return this.$store.getters.getterCategoryLuatChoi?.filter((item) => {
          return item.tag.includes('xsmn');
        });
      },
      set(newVal) {
        this.$store.commit('setCategoryLuatChoi', newVal);
      }
    }
  },
  mounted() {},
  created() {
    if (!this.isLuatChoi) {
      this.getArticleByCategoryLuatChoi();
    }
    this.getArticleByCategorySupport();
    this.getArticleByCategoryWithdraw();
  },
  methods: {
    handlerLink(link = '') {
      this.visible = false;
      this.$router.replace(link);
    },
    handlerActiveTab(tag) {
      return this.$route.params.slug === tag;
    },
    updateTitleMobileArticleList(event) {
      this.title = event;
    },
    getArticleByCategoryLuatChoi() {
      this.$store.dispatch('getArticleByCategorySlug', 'luat-choi').then((resp) => {
        this.categoryLuatChoi = resp;
      });
    },
    getArticleByCategorySupport() {
      this.$store.dispatch('getArticleByCategorySlug', 'ho-tro').then((resp) => {
        this.categorySupport = resp;
      });
    },
    getArticleByCategoryWithdraw() {
      this.$store.dispatch('getArticleByCategorySlug', 'nap-rut-tien').then((resp) => {
        this.categoryWithdraw = resp;
      });
    }
  }
};
</script>
<style lang="scss">
// @import "../assets/sass/main";
.colorContentBannerContact {
  color: rgba(255, 255, 255, 0.7);
}
.tabItemLuatChoi {
  height: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  transition: 0.3s all;
  &:hover {
    color: #333333;
    background-color: #f7f7f7;
  }
  &.activedTab {
    background-color: #f7f7f7;
    color: #00509d;
    font-weight: 600;
  }
}
.bannerContact {
  height: 220px;
  background: #fff;
  position: relative;
  .imgBackground {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.contentBannerContact {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
}

@media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .btnChooseMoney {
    color: #333333;
    padding: 0.375rem 2rem 0.375rem 1rem;
    background-color: #fff;
    position: relative;
    display: flex;
    border-radius: 3px;
    align-items: center;
    transition: 0.3s all;
    height: 40px;
    //   &:hover {
    //     background-color: #d9d9d9;
    //   }
    &:after {
      content: '';
      position: absolute;
      right: 11px;
      display: inline-block;
      margin-top: 0;
      width: 8px;
      height: 8px;
      border-top: 2px solid #333 !important;
      border-right: 2px solid #333;
      border-left: none;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      top: 14px !important;
    }
  }

  .myToggle {
    border: none;
    border-radius: 3px;
    overflow: inherit;
    .card-header {
      box-shadow: none;
      border: none;
      background-color: white;
      padding: 0 2px 0 10px;
    }
    .myToggleBtn {
      background-color: white;
      border: none;
      display: flex;
      justify-content: space-between;
      padding: 0;
      p:nth-child(1) {
        margin: auto 0;
      }
    }
    .myToggleContent {
      .card-body {
        padding: 0;
        .boxFilter {
          padding: 10px 10px 10px 10px;
          .timeDialFilter {
            .form-control {
              width: 126px;
              height: 40px;
              border-radius: 3px;
              border: solid 1px #efefef;
              background-color: #f7f7f7;
              padding: 0.375rem 0.5rem 0.375rem 0.25rem;
              margin-right: 20px;
            }
            .iconTimeDial {
              right: 15px;
            }
          }
          .filterDateDial {
            .form-control {
              width: 76px;
              padding: 0.375rem 0rem 0.375rem 0.75rem;
            }
            .toPeriod {
              width: 84px;
              margin-right: 0;
            }
          }
          .boxFilterBtn {
            position: unset;
            display: flex;
            width: 100%;
            .boxFilterBtnChild {
              display: flow-root;
              position: unset;
              width: 100%;
              .btnFilter {
                width: 100%;
                margin-bottom: 10px;
              }
              .btnFilterLink {
                width: 100%;
              }
            }
          }
          .filterLeft {
            display: flex;
            margin-bottom: 10px;
          }
          .choosePeriod {
            margin-top: 20px;
            .rememberPassword {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .btnConfirm {
    margin-top: 1.75rem;
  }
  .layoutSubMain {
    // min-height: auto !important;
  }
  .bannerContact {
    height: 282px;
    .imgBackground {
      img {
        object-fit: cover;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .w-300-576 {
    width: 300px;
  }
}
</style>
